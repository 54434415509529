import React, { useEffect } from "react";
import welcomeBack from "assets/images/welcome.png";

import { Link, useNavigate } from "react-router-dom";
import { useForm, type SubmitHandler } from "react-hook-form";
import { useSignUp } from "utils/api/auth.api";
import { type Register } from "utils/types/user.type";
import { Input } from "components/input";
import toast from "react-hot-toast";
import Logo from "../../assets/icons/logo.svg";

// import WalletIcon from "assets/images/WalletIcon.svg";

export default function Signup() {
  const navigate = useNavigate();
  const { mutate: signup, isLoading, isSuccess, data } = useSignUp();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Register>();

  const onSubmit: SubmitHandler<Register> = (data) => signup(data);

  const userPassword = watch("password");

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully signup!");
      navigate("/verify-email", { state: { email: data?.user?.email } });
    }
  }, [isSuccess]);

  return (
    <section className="signup-section">
      <div className="flex flex-col h-full ">
        <header
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            paddingLeft: "30px",

            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {" "}
          <img style={{ height: "50px" }} src={Logo} alt="" />{" "}
          {/* <span className="text-black text-xl  whitespace-nowrap">
            Paysela
          </span> */}
        </header>
        <div className="grid lg:grid-cols-12 h-screen gap-3">
          <div className="lg:col-span-6 flex items-center justify-center">
            <div className="signup-wrapper w-full max-w-md mx-auto p-6">
              <h5 className=" text-[#030319] text-[32px] font-[700] mb-6 xl:mb-10 font-[Poppins]">
                CREATE AN ACCOUNT
              </h5>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises  */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ display: "flex", gap: "24px" }}>
                  <div className="mb-4">
                    <Input
                      isInvalid={Boolean(errors?.firstName)}
                      errorMessage={errors?.firstName?.message}
                      label="First Name"
                      type="text"
                      placeholder=""
                      name="firstName"
                      register={register}
                      rules={{
                        required: "First name is required",
                      }}
                    />
                  </div>

                  <div className="mb-4">
                    <Input
                      isInvalid={Boolean(errors?.lastName)}
                      errorMessage={errors?.lastName?.message}
                      label="Last Name"
                      type="text"
                      placeholder=""
                      name="lastName"
                      register={register}
                      rules={{
                        required: "Last name is required",
                      }}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <Input
                    isInvalid={Boolean(errors?.email)}
                    errorMessage={errors?.email?.message}
                    label="Email"
                    type="email"
                    placeholder=""
                    name="email"
                    register={register}
                    rules={{
                      required: "Email is required",
                    }}
                  />
                </div>

                <div className="mb-4">
                  <Input
                    isInvalid={Boolean(errors?.password)}
                    errorMessage={errors?.password?.message}
                    label="Password"
                    type="password"
                    placeholder="********"
                    name="password"
                    register={register}
                    rules={{
                      required: "Password is required",
                      validate: {
                        isCharacter: (value: string) =>
                          // eslint-disable-next-line
                          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(
                            value
                          ) || "Must contain one Special character",
                        // eslint-disable-next-line
                        isSmall: (value: string) =>
                          /[a-z]/.test(value) ||
                          "Must contain one Small letter",
                        isCapital: (value: string) =>
                          /[A-Z]/.test(value) ||
                          "Must contain one Capital character",
                        isDigit: (value: string) =>
                          /\d/.test(value) ||
                          "Must contain one Digit character",
                      },
                      minLength: {
                        value: 8,
                        message: "Minimum length should be 8",
                      },
                    }}
                  />
                </div>

                <div className="mb-4">
                  <Input
                    isInvalid={Boolean(errors?.confirmPassword)}
                    errorMessage={errors?.confirmPassword?.message}
                    label="Confirm Password"
                    type="password"
                    placeholder="********"
                    name="confirmPassword"
                    register={register}
                    rules={{
                      required: "Re-enter Password",
                      validate: {
                        isMatch: (value: string) =>
                          userPassword === value || "Password did not match",
                      },
                    }}
                  />
                </div>
                <div className="mb-4 lg:mb-4">
                  <div className="inline-flex items-center ">
                    <label
                      className="relative flex items-center p-3 rounded-full cursor-pointer"
                      htmlFor="check"
                    >
                      <input
                        type="checkbox"
                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#F7A600] checked:bg-[#F7A600] "
                        id="check"
                        {...register("isAgree", {
                          required: "Agree to terms & conditions",
                        })}
                      />
                      <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          // stroke-width="1"
                        >
                          <path
                            // fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            // clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    <label
                      className=" cursor-pointer select-none"
                      htmlFor="check"
                    >
                      I agree to the{" "}
                      <strong className="text-xs leading-relaxed font-bold">
                        Terms & Conditions.
                      </strong>
                    </label>
                  </div>

                  {Boolean(errors?.isAgree) && (
                    <span className="text-red-800">
                      {errors?.isAgree?.message}
                    </span>
                  )}
                </div>

                <button
                  type="submit"
                  className="bg-cyan-800  hover:text-cyan-800 hover:bg-cyan-300 disabled:bg-cyan-300 text-white text-base font-medium border border-cyan-800 rounded-lg w-full p-3 transition"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div
                      className="w-8 h-8 rounded-full animate-spin mx-auto
                    border-4 border-solid border-cyan-800 border-t-transparent"
                    ></div>
                  ) : (
                    "CREATE AN ACCOUNT"
                  )}
                </button>

                <div className="text-center" style={{ padding: "20px" }}>
                  <Link
                    to="/login"
                    className="text-cyan-800 ttext-x font-heavy whitespace-nowrap"
                  >
                    Login instead?
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <div className="lg:col-span-6 relative lg:overflow-hidden">
            <div className="signup-bg h-full flex items-center justify-center">
              <img
                style={{ height: "680px" }}
                className="max-w-xs lg:max-w-none object-contain"
                src={welcomeBack}
                alt="title"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
