/* eslint-disable react/prop-types */
// components/Modal.js
import React from "react";

export default function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0  bg-black-800 bg-opacity-50 overflow-auto  flex items-center justify-center z-50">
      <div className="relative flex items-center justify-center bg-white rounded-lg overflow-auto-lg py-4 px-6 max-w-[550px] w-full ">
        {children}
      </div>
    </div>
  );
}
