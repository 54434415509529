import Layout from "layout";
import React, { useState } from "react";
import {
  useGetBuyTransactions,
  useGetBuyTransactionsApproval,
  useGetDepositBank,
  useUpdateBuyTransaction,
  useUpdateDepositBank,
} from "utils/api/user.api";
import { type UserTransaction } from "utils/types/user.type";
import { Tabs } from "antd";
import { styled } from "styled-components";
import { BankAccountModal } from "../../components/module";

export default function MytsionOrder() {
  const { data: completedTrans, isLoading } = useGetBuyTransactions("true");
  const { data: pendingTrans } = useGetBuyTransactionsApproval();
  const { data: usdDepositBank } = useGetDepositBank("usd");
  const { data: ngnDepositBank } = useGetDepositBank("ngn");
  const updateDepositBank = useUpdateDepositBank();

  // console.log(completedTrans)
  // console.log(pendingTrans);

  const [isUsdModalOpen, setIsUsdModalOpen] = useState(false);
  const [isEurModalOpen, setIsEurModalOpen] = useState(false);

  const handleUsdUpdate = (data: any) => {
    if (!usdDepositBank?.id) {
      console.error("No bank ID found");
      return;
    }

    updateDepositBank.mutate(
      {
        ...data,
        id: usdDepositBank.id,
        currency: "usd",
        quote: usdDepositBank?.quote || "",
      },
      {
        onSuccess: () => {
          setIsUsdModalOpen(false);
        },
      }
    );
  };

  const handleEurUpdate = (data: any) => {
    if (!ngnDepositBank?.id) {
      console.error("No bank ID found");
      return;
    }

    updateDepositBank.mutate(
      {
        ...data,
        id: ngnDepositBank.id,
        currency: "ngn",
        quote: ngnDepositBank?.quote || "",
      },
      {
        onSuccess: () => {
          setIsEurModalOpen(false);
        },
      }
    );
  };

  const CustomTabs = styled(Tabs)`
    .ant-tabs-tab:hover .ant-tabs-tab-btn {
      color: #f7a600 !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: black !important;
    }

    .ant-tabs-ink-bar {
      background-color: #f7a600 !important;
    }
    .ant-tabs-nav-list {
      padding-left: 24px !important;
    }
    .ant-tabs-tab-btn {
      color: #8f92a1;
    }
  `;
  const items = [
    {
      key: "1",
      label: "Pending Transactions",
      children: (
        <div className="dashboard-sidebar table-wrapper max-h-screen overflow-auto">
          <table
            className="w-full border border-[#F1F1F1] "
            style={{ borderCollapse: "collapse", borderSpacing: "0" }}
          >
            <thead className="bg-cyan-300 h-26 px-5 py-2 sticky top-[-1px] z-10">
              {" "}
              <tr>
                <th style={{ width: "20%" }} className="py-3 px-5 text-left">
                  Client
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Email
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Ordered Amount
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Order Date
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Status
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {pendingTrans?.map((item) => (
                <TableRow1 key={item?.id} row={item} />
              ))}
            </tbody>
          </table>
        </div>
      ),
    },
    {
      key: "2",
      label: "Completed Transactions",
      children: (
        <div className="dashboard-sidebar table-wrapper h-screen overflow-auto ">
          <table
            className="w-full border border-[#F1F1F1] "
            style={{ borderCollapse: "collapse", borderSpacing: "0" }}
          >
            <thead className="bg-cyan-300 h-26 px-5 py-2 sticky top-[-1px] z-10">
              {" "}
              <tr>
                <th style={{ width: "20%" }} className="py-3 px-5 text-left">
                  Client
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Email
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Ordered Amount
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Order Date
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {completedTrans?.map((transaction) => (
                <TableRow key={transaction?.id} transaction={transaction} />
              ))}
              {!isLoading &&
                completedTrans?.filter((item) => item.user?.email)?.length ===
                  0 && (
                  <tr>
                    <td>No Results Found</td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div style={{ backgroundColor: " #FAFAFA" }} className="dashboard-main">
        <div>
          <div className=" relative h-40 flex items-center bg-cyan-800 rounded-lg min-w-[300px]">
            <text
              style={{
                fontWeight: "500",
                fontSize: "32px",
                color: "white",
                padding: "24px",
              }}
            >
              Mystion Orders
            </text>
          </div>
          {/* Bank Account Detail */}
          <div className="w-full flex flex-col p-6 mt-6 bg-white rounded-md">
            <h2 className="text-lg font-bold mb-6">Bank Accounts Detail</h2>
            <div className="flex flex-col lg:flex-row justify-between gap-6">
              {/* Bank for USD Card */}
              <div className="w-full lg:w-1/2 bg-[#FAFAFA] rounded-lg shadow-md p-6">
                <h2 className="text-lg mb-4">Bank for USD</h2>
                <div className="space-y-4">
                  {/* Account Holder */}
                  <div className="border-b border-gray-300 p-2 flex justify-between">
                    <p className="text-gray-600">Account Holder</p>
                    <p className="text-black">
                      {usdDepositBank?.accountHolderName}
                    </p>
                  </div>

                  {/* Bank */}
                  <div className="border-b border-gray-300 p-2 flex justify-between">
                    <p className="text-gray-600">Bank</p>
                    <p className="text-black">{usdDepositBank?.bank}</p>
                  </div>

                  {/* Account Number */}
                  <div className="border-b border-gray-300 p-2 flex justify-between">
                    <p className="text-gray-600">Account Number</p>
                    <p className="text-black">
                      {usdDepositBank?.accountNumber}
                    </p>
                  </div>

                  <button
                    type="button"
                    onClick={() => setIsUsdModalOpen(true)}
                    className={`w-[5em] py-2 rounded-lg bg-[#F1F1F1] text-black border border-[#8F92A1] cursor-pointer`}
                  >
                    EDIT
                  </button>
                </div>
              </div>

              {/* Bank for NGN Card */}
              <div className="w-full lg:w-1/2 bg-[#FAFAFA] rounded-lg shadow-md p-6">
                <h2 className="text-lg mb-4">Bank for NGN</h2>
                <div className="space-y-4">
                  {/* Account Holder */}
                  <div className="border-b border-gray-300 p-2 flex justify-between">
                    <p className="text-gray-600">Account Holder</p>
                    <p className="text-black">
                      {ngnDepositBank?.accountHolderName}
                    </p>
                  </div>

                  {/* Bank */}
                  <div className="border-b border-gray-300 p-2 flex justify-between">
                    <p className="text-gray-600">Bank</p>
                    <p className="text-black">{ngnDepositBank?.bank}</p>
                  </div>

                  {/* Account Number */}
                  <div className="border-b border-gray-300 p-2 flex justify-between">
                    <p className="text-gray-600">Account Number</p>
                    <p className="text-black">
                      {ngnDepositBank?.accountNumber}
                    </p>
                  </div>

                  <button
                    type="button"
                    onClick={() => setIsEurModalOpen(true)}
                    className={`w-[5em] py-2 rounded-lg bg-[#F1F1F1] text-black border border-[#8F92A1] cursor-pointer`}
                  >
                    EDIT
                  </button>
                </div>
              </div>
            </div>

            {/* Add Modals */}
            <BankAccountModal
              isOpen={isUsdModalOpen}
              onClose={() => setIsUsdModalOpen(false)}
              onUpdate={handleUsdUpdate}
              initialData={usdDepositBank}
              currency="usd"
            />

            <BankAccountModal
              isOpen={isEurModalOpen}
              onClose={() => setIsEurModalOpen(false)}
              onUpdate={handleEurUpdate}
              initialData={ngnDepositBank}
              currency="ngn"
            />
          </div>

          {/* Table */}
          <div className="flex flex-col  ">
            <CustomTabs
              defaultActiveKey="1"
              items={items}
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({ transaction }: { transaction: UserTransaction }) {
  const [isEdit, setIsEdit] = useState(false);

  // Determine the row and text color based on the transaction type
  const getRowColor = (type: string) => {
    switch (type) {
      case "Withdraw Crypto":
        return "bg-[#E7FFF2]";
      case "Deposit Crypto":
        return "bg-[#FFE3E3]";
      case "Swap Crypto To Crypto":
        return "bg-[#F1F1F1]";
      case "Swap Fiat To Crypto":
        return "bg-[#F1F1F1]";
      case "Swap Crypto To Fiat":
        return "bg-[#F1F1F1]";
      default:
        return "bg-[#ffffff]";
    }
  };
  // console.log(transaction.amount);
  const renderDetails = () => {
    return (
      <>
        <td className="flex-1">
          <strong className="text-xs">Ammount paid:</strong>
          <p className="text-[#8F92A1] text-xs">
            {transaction?.currency?.symbol}
            {transaction?.amount}
          </p>
        </td>
        <td className="flex-1">
          <strong className="text-xs">Receipt Proof:</strong>
          <div className="text-black-900 h-24  text-xs">
            <img
              style={{ height: "100px", width: "100px" }}
              src={transaction?.proofOfPayment}
              alt=""
              className="rounded-2xl"
            />
          </div>
        </td>
        <td className="flex-1">
          <strong className="text-xs">Status:</strong>
          <p
            className={`text-xs font-semibold ${
              transaction?.status === "Completed"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {transaction?.status}
          </p>
        </td>
      </>
    );
  };

  // Determine the button class based on the isEdit state
  const buttonClass = isEdit
    ? "text-cyan-800 bg-cyan-300 border-cyan-800"
    : "text-gray-800  bg-[#F1F1F1]  border-[#8F92A1]";

  return (
    <>
      <tr className={`${getRowColor(transaction?.type)}  transition`}>
        <td className="py-5 border-b border-grey-800/30  px-5">
          <p className="text-[#8F92A1] text-xs ">
            {transaction?.user?.fullname}
          </p>
        </td>
        <td className="py-5 border-b border-grey-800/30  px-2">
          <p className="text-[#8F92A1] text-xs ">{transaction?.user?.email}</p>
        </td>

        <td className="py-5 border-b border-grey-800/30  px-2">
          <p className=" text-sm text-[#8F92A1] flex gap-1">
            {transaction?.type.includes("Withdraw Crypto") &&
              transaction?.currency?.symbol}
            {transaction?.swappedAmount.toFixed(3)} MSTN
          </p>
        </td>
        <td className="px-2 border-r-0 py-5 border-b border-grey-800/30 ">
          <p className="text-[#8F92A1] text-xs ">
            {new Date(transaction?.createdAt)
              .toLocaleString("en-GB", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
              .replace(" at", ",")}
          </p>
        </td>
        <td className="px-2 border-l-0 py-5 border-b border-grey-800/30 ">
          <button
            onClick={() => setIsEdit(!isEdit)}
            type="button"
            className={`text-xs font-medium  rounded-lg px-8 py-2.5 border whitespace-nowrap ${buttonClass}`}
          >
            {isEdit ? "COLLAPSE" : "VIEW DETAIL"}
          </button>
        </td>
      </tr>
      {isEdit && (
        <tr className="relative w-full">
          <td colSpan={6} className="p-0 border-t-0 w-full">
            <table className="px-6 pb-6 w-full">
              <tbody className="w-full">
                <tr className="flex">{renderDetails()}</tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}
function TableRow1({ row }: { row: UserTransaction }) {
  const [isReview, setReview] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { mutate: updateTransaction, isLoading } = useUpdateBuyTransaction();

  // Determine the button class based on the isEdit state
  const buttonClass = isEdit
    ? "text-cyan-800 bg-cyan-300 border-cyan-800"
    : "text-gray-800 bg-[#F1F1F1]  border-[#8F92A1]";

  // Toggle detail view and edit mode
  const toggleDetail = () => {
    setReview(!isReview);
    setIsEdit(!isEdit);
  };

  console.log(row);

  return (
    <>
      <tr className={isReview ? "" : "bg-white"}>
        <td className="border-b border-grey-800/30 py-5 px-5">
          <p className="text-[#8F92A1] text-sm ">{row?.user?.fullname}</p>
        </td>
        <td className="border-b border-grey-800/30 py-5 px-2">
          <p className="text-[#8F92A1] text-sm ">{row?.user?.email}</p>
        </td>
        <td className="border-b border-grey-800/30 py-5 px-2">
          <p className=" text-sm text-[#8F92A1] flex gap-1">
            {row?.type.includes("Withdraw Crypto") && row?.currency?.symbol}
            {row?.swappedAmount.toFixed(3)} MSTN
          </p>
        </td>
        <td className="border-b border-grey-800/30 rounded-l-lg py-5 px-2">
          <p className="text-[#8F92A1] text-sm ">
            {" "}
            {new Date(row?.createdAt)
              .toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
              .replace(/\//g, "-")}
          </p>
        </td>

        <td className="border-b border-grey-800/30 py-5  text-left px-2">
          <p className="text-[#8F92A1] text-sm ">{row?.status}</p>
        </td>
        <td className="border-b border-grey-800/30 py-5 px-2">
          <button
            onClick={() => {
              toggleDetail();
            }}
            type="button"
            className={`text-xs font-semibold rounded-lg px-8 py-2.5 border whitespace-nowrap ${buttonClass}`}
          >
            {isEdit ? "COLLAPSE" : "VIEW DETAIL"}
          </button>
        </td>
      </tr>
      {isReview && (
        <tr className="relative w-full">
          <td colSpan={6} className="p-0 border-t-0 w-full">
            <table className="px-6 pb-6 w-full">
              <tbody className="w-full">
                <tr className="flex ">
                  <td className="flex-1">
                    <strong className="text-xs">Ammount paid:</strong>
                    <p className="text-[#8F92A1] text-xs">
                      {row?.currency?.symbol === "₦"
                        ? `₦${row?.amount}`
                        : `$${row?.amount}`}
                    </p>
                  </td>
                  <td className="flex-1">
                    <strong className="text-xs">Receipt Proof:</strong>
                    <div className="text-black-900 h-24  text-xs">
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={row?.proofOfPayment}
                        alt=""
                        className="rounded-2xl"
                      />
                    </div>
                  </td>

                  <td className="flex-1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <button
                        type="button"
                        onClick={() =>
                          updateTransaction({
                            transactionId: row?.id,
                            status: "Rejected",
                          })
                        }
                        className="text-black text-xs bg-gray-200 font-medium rounded-lg px-8 py-2.5"
                        disabled={isLoading}
                      >
                        Reject
                      </button>
                      <button
                        onClick={() =>
                          updateTransaction({
                            transactionId: row?.id,
                            status: "Completed",
                          })
                        }
                        type="button"
                        className="bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-xs font-medium border border-cyan-800 rounded-lg px-8 py-2.5 transition"
                        disabled={isLoading}
                      >
                        Approve
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}
