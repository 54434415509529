/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Layout from "layout";
import React, { useEffect, useState } from "react";
import {
  useGetUserList,
  useGetUserWalletBalance,
  useUpdateUser,
} from "utils/api/user.api";
import { type ListedUser } from "utils/types/user.type";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import avatar from "assets/icons/imgPlaceholder.svg";
import searchIconSrc from "assets/icons/searchIcon.svg";
import DropIcon from "assets/icons/DropIcon.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "components/modal";
import * as yup from "yup";
import { Tabs } from "antd";
import styled from "styled-components";

export default function UserDictionary() {
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") ?? ""
  );

  const { data, isLoading } = useGetUserList();
  console.log("🚀 ~ UserDictionary ~ data:", data);
  return (
    <Layout>
      <div className="dashboard-main " style={{ backgroundColor: " #FAFAFA" }}>
        <div className=" bg-cyan-800  relative flex flex-col lg:flex-row justify-between p-5 lg:p-6 items-center rounded-lg h-40 ">
          <text className="font-[500] text-[24px] lg:text-[32px] text-[#FFFFFF]">
            User Management
          </text>

          <div className="relative w-full lg:w-auto">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search by Email "
              className="w-full lg:w-[390px] text-grey-700 text-sm rounded-lg border border-grey-600 focus:outline-0 py-3.5 px-5 pr-10"
            />
            <img
              src={searchIconSrc}
              alt="Search"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-700 w-5 h-5"
            />
          </div>
        </div>
        <div className="main-wrapper max-w-full py-6">
          <div className="dashboard-sidebar table-wrapper max-h-[30rem] overflow-auto ">
            <table
              className="w-full border border-[#F1F1F1]"
              style={{ borderCollapse: "collapse", borderSpacing: "0" }}
            >
              <thead className="bg-cyan-300 h-26 px-5 py-2 sticky top-[-1px] z-10">
                <tr>
                  <th className="py-3 px-5 text-left">Created</th>
                  <th className="py-3 px-2 text-left">Name</th>
                  <th className="py-3 px-2 text-left">Email</th>
                  <th className="py-3 px-2 text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {data
                  ?.filter((item) => item.email.includes(searchValue))
                  ?.map((item) => <TableRow key={item?.id} user={item} />)}
                {!isLoading &&
                  data?.filter((item) => item.email.includes(searchValue))
                    ?.length === 0 && (
                    <tr>
                      <td>No Results Found</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const CustomTabs = styled(Tabs)`
  .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: #f7a600 !important;
    display: flex;
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f7a600 !important;
    display: flex;
    width: 100%;
  }

  .ant-tabs-ink-bar {
    background-color: #f7a600 !important;
    display: flex;
    width: 100%;
  }
  .ant-tabs-nav-list {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  .ant-tabs-tab-btn {
    color: #8f92a1;
  }
`;
function TableRow({ user }: { user: ListedUser }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { mutate: updateUserData, isSuccess } = useUpdateUser();
  const { data: userWallet } = useGetUserWalletBalance(user?.id);
  const [openModal, setOpenModal] = useState(false);

  const onSubmit = (data: any) => {
    const updateData = { userId: user.id, ...data };
    updateUserData(updateData);
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenModal(false);
      toast.success("User updated successfully!");
    }
  }, [isSuccess]);

  useEffect(() => {
    setValue("status", user.isActive ? "active" : "de-active");
  }, [user.isActive, setValue]);

  const items = [
    {
      key: "1",
      label: "Crypto",
      children: (
        <div
          className="dashboard-sidebar w-[99%] flex my-2 flex-col gap-10 overflow-y-auto overflow-x-hidden border border-[#F1F1F1] rounded-lg p-3"
          style={{ maxHeight: "calc(5 * 50px)" }}
        >
          {userWallet?.balance?.length &&
            userWallet?.balance?.map((item) => (
              <div key={item?.id} className="">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>
                    <h6 className="text-[16px] font-bold">{item?.name}</h6>
                    <p className="text-sm text-[#8F92A1] font-[400]">
                      {item?.address}
                    </p>
                  </div>
                  <div className="flex gap-2 mt-2">
                    <div className="flex">
                      <span className="font-bold text-[14px]">
                        {new Intl.NumberFormat("en", {
                          maximumFractionDigits: 4,
                        }).format(item?.balance)}{" "}
                      </span>
                      <span className="whitespace-nowrap font-bold">
                        {item?.symbol}
                      </span>
                    </div>
                    <img src={item?.logoUrl} className="w-6 h-6" />
                  </div>
                </div>
              </div>
            ))}
        </div>
      ),
    },
    {
      key: "2",
      label: "Fiat Currencies",
      children: (
        <div
          className="dashboard-sidebar w-[99%] flex my-2 flex-col gap-10 overflow-y-auto overflow-x-hidden border border-[#F1F1F1] rounded-lg p-3"
          style={{ maxHeight: "calc(5 * 50px)" }}
        >
          {userWallet?.wallet &&
            userWallet?.wallet.fiatBalance?.map((item) => (
              <div key={item?.id} className="">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>
                    <h6 className="text-[16px] font-bold">
                      {item?.currency?.name}
                    </h6>
                    <p className="text-sm text-[#8F92A1] font-[400]">
                      {item?.walletId}
                    </p>
                  </div>
                  <div className="flex gap-2 mt-2">
                    <div className="flex  gap-1">
                      <span className="whitespace-nowrap font-bold">
                        {item?.currency?.symbol}{" "}
                      </span>
                      <span className="font-bold text-[14px]">
                        {new Intl.NumberFormat("en", {
                          maximumFractionDigits: 4,
                        }).format(item?.balance)}{" "}
                      </span>
                    </div>
                    {item?.currency?.logoUrl && (
                      <img src={item?.currency?.logoUrl} className="w-6 h-6" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ),
    },
  ];

  return (
    <>
      <tr className="border-b border-gray-400 bg-[#FFFFFF]">
        <td className="py-5 px-4 rounded-l-lg">
          <p className="text-[#8F92A1] text-sm ">
            {" "}
            {new Date(user?.createdAt)
              .toLocaleString("en-GB", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
              .replace(/\//g, "-")}
          </p>
        </td>
        <td className="py-5 ">
          <p className="text-[14px] font-[400] text-[#8F92A1]">
            {user?.fullname}
          </p>
        </td>
        <td className="py-5 ">
          <p className="text-[14px] font-[400] text-[#8F92A1]">{user?.email}</p>
        </td>

        <td className="flex gap-1 py-5  rounded-r-lg w-[100px]">
          <button
            className="text-gray-800 text-xs  bg-[#F1F1F1] border border-[#8F92A1]   font-medium rounded-lg py-2 px-6 whitespace-nowrap"
            onClick={() => setOpenModal(true)}
          >
            EDIT USER
          </button>
        </td>
      </tr>

      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <div className="flex flex-col items-center w-full">
          <div className="flex items-center justify-between  w-full border-b pb-4 mb-4">
            <img
              src={user?.image || avatar}
              alt="avatar"
              className="w-12 h-12 rounded-full mr-3"
            />
            <div>
              <p className="text-lg font-bold">{user?.fullname}</p>
              <p className="text-sm text-gray-500">{user?.email}</p>
            </div>
            <div className="ml-auto text-sm">
              <p className="font-semibold">Date Created</p>
              <p className="text-gray-500">
                {new Date(user?.createdAt)
                  .toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                  .replace(/\//g, "-")}{" "}
              </p>
            </div>
          </div>

          <CustomTabs
            defaultActiveKey="1"
            items={items}
            style={{
              width: "100%",
            }}
          />

          <form onSubmit={handleSubmit(onSubmit)} className="mt-4 w-full">
            <select
              id="select-1"
              className="appearance-none w-full border border-[#E0E3E8] text-[#000000]  font-[400] text-[16px]   p-3 rounded-lg focus:outline-0 mb-3"
              defaultValue={"de-active"}
              {...register("status")}
              style={{
                WebkitAppearance: "none",
                MozAppearance: "none",
                appearance: "none",
                background: `url(${DropIcon}) no-repeat  right  1rem center / 1em`,
              }}
            >
              <option className="bg-black-300" value="active">
                Activate
              </option>
              <option className="bg-black-300" value="de-active">
                Deactivate
              </option>
            </select>

            <div className="w-full flex items-center justify-between gap-2">
              <button
                onClick={() => {
                  setOpenModal(false);
                }}
                className="w-full text-white text-[16px] bg-[#8F92A1] font-medium rounded-lg px-6 py-4"
              >
                CANCEL
              </button>
              <button
                onClick={handleSubmit(onSubmit)}
                className="w-full bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-[16px] font-medium border  rounded-lg px-6 py-4 transition"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
