import Layout from "layout";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import {
  useAddOrganization,
  useUpdateOrganization,
  useGetOrganizations,
  useDeleteOrganization,
} from "utils/api/user.api";
import Modal from "components/modal";

export default function CharityDonation() {
  const { data: organizations } = useGetOrganizations();

  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<"add" | "edit">("add");
  const [selectedOrganization, setSelectedOrganization] = useState<any | null>(
    null
  );

  const handleAddNewOrganization = () => {
    setModalType("add");
    setSelectedOrganization(null);
    setOpenModal(true);
  };

  const handleEditOrganization = (organization: any) => {
    setModalType("edit");
    setSelectedOrganization(organization);
    setOpenModal(true);
  };

  return (
    <Layout>
      <div className="dashboard-main" style={{ backgroundColor: "#FAFAFA" }}>
        <div className="relative h-40 bg-cyan-800 rounded-lg min-w-[300px] p-6 flex items-center justify-between">
          <text
            style={{
              fontWeight: "500",
              fontSize: "32px",
              color: "white",
            }}
          >
            Charity & Donation
          </text>
          <button
            onClick={handleAddNewOrganization}
            className="flex gap-2 text-cyan-800 text-xs bg-cyan-300 border border-white font-[400] rounded-lg px-4 py-2.5"
          >
            ADD ORGANIZATION
          </button>
        </div>
        <div className="main-wrapper max-w-full py-6">
          <div className="dashboard-sidebar table-wrapper max-h-[27rem] overflow-auto">
            <table
              className="w-full border border-[#F1F1F1] rounded-lg "
              style={{ borderCollapse: "collapse", borderSpacing: "0" }}
            >
              <thead className="bg-cyan-300 h-26 px-5 py-2 sticky top-[-1px] z-10">
                <tr className="flex py-4">
                  <th className="flex-1 px-5 text-left">Organization</th>
                  <th className="flex-1 text-left">Wallet Address</th>
                  <th className="flex-1 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {organizations?.map(
                  (org: any, index: React.Key | null | undefined) => (
                    <TableRow
                      key={index}
                      organization={org}
                      onEdit={handleEditOrganization}
                    />
                  )
                )}
                {organizations?.length === 0 && (
                  <tr>
                    <td>No Results Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <OrganizationModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          modalType={modalType}
          organization={selectedOrganization}
        />
      </div>
    </Layout>
  );
}

function TableRow({
  organization,
  onEdit,
}: {
  onEdit: (organization: any) => void;
  organization: any;
}) {
  const { mutate: deleteOrganization, isSuccess } = useDeleteOrganization();

  const handleDelete = () => {
    deleteOrganization(organization?.id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Organization deleted successfully!");
    }
  }, [isSuccess]);

  return (
    <tr className="flex items-center border-b border-[#F1F1F1] bg-[#FFFFFF]">
      <td className="flex-1 py-2 px-4 text-[#8F92A1] rounded-l-lg">
        {organization?.name}
      </td>
      <td className="flex-1 py-2 px-5 text-[#8F92A1] text-left">
        {organization?.walletAddress}
      </td>
      <td className="flex-1 py-2 rounded-r-lg text-center flex items-center justify-center">
        <button
          className="text-[#030319] text-xs bg-[#F1F1F1] border border-[#8F92A1] font-medium rounded-lg px-4 py-2.5"
          onClick={() => onEdit(organization)}
        >
          EDIT
        </button>
        <button
          onClick={handleDelete}
          className="text-[#EF4444] text-xs bg-[#FFD8D8] border border-[#EF4444] font-[400] rounded-lg px-7 py-2.5 ml-2"
        >
          DELETE
        </button>
      </td>
    </tr>
  );
}

function OrganizationModal({
  isOpen,
  onClose,
  modalType,
  organization,
}: {
  isOpen: boolean;
  onClose: () => void;
  modalType: "add" | "edit";
  organization: any | null;
}) {
  const { mutate: addOrganization } = useAddOrganization();
  const { mutate: updateOrganization } = useUpdateOrganization();
  const [organizationData, setOrganizationData] = useState({
    name: "",
    walletAddress: "",
  });

  const handleSubmit = () => {
    if (modalType === "add") {
      addOrganization(organizationData);
    } else if (modalType === "edit" && organization) {
      updateOrganization({
        ...organizationData,
        id: organization.id,
        isActive: true,
      });
    }
    onClose();
  };

  useEffect(() => {
    if (organization) {
      setOrganizationData({
        name: organization.name,
        walletAddress: organization.walletAddress,
      });
    }
  }, [organization]);

  useEffect(() => {
    if (modalType === "add") {
      setOrganizationData({
        name: "",
        walletAddress: "",
      });
    } else {
      setOrganizationData({
        name: organization?.name,
        walletAddress: organization?.walletAddress,
      });
    }
  }, [onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4 w-full">
        <h1 className="text-[18px] font-[600] text-[#030319]">
          {modalType === "add" ? "Add New Organization" : "Edit Organization"}
        </h1>

        {/* Organization Name Label and Input */}
        <div>
          <label className="text-[#8F92A1] text-[14px] mb-1">
            Organization Name
          </label>
          <input
            type="text"
            placeholder="Organization Name"
            className="w-full p-3 border border-[#E0E3E8] rounded-lg mb-4"
            value={organizationData.name}
            onChange={(e) =>
              setOrganizationData({ ...organizationData, name: e.target.value })
            }
          />
        </div>

        <div>
          <label className="text-[#8F92A1] text-[14px] mb-1">
            Wallet Address
          </label>
          <input
            type="text"
            placeholder="Wallet Address"
            className="w-full p-3 border border-[#E0E3E8] rounded-lg mb-4"
            value={organizationData.walletAddress}
            onChange={(e) =>
              setOrganizationData({
                ...organizationData,
                walletAddress: e.target.value,
              })
            }
          />
        </div>

        <div className="w-full flex items-center justify-between gap-2">
          <button
            onClick={onClose}
            className="w-full text-[#000000] text-[16px] font-medium bg-[#F8F8F8] rounded-lg px-6 py-4"
          >
            CANCEL
          </button>
          <button
            onClick={handleSubmit}
            className="w-full bg-[#FF9800] text-white text-[16px] font-medium rounded-lg px-6 py-4"
          >
            {modalType === "add" ? "ADD" : "UPDATE"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
