import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import logo from "assets/images/logo.svg";
// import closeIcon from "assets/images/close-icon.svg";
import pTransImg from "assets/icons/transaction-approval.svg";
import cTransImg from "assets/icons/transcation-history.svg";
import feeManagement from "assets/icons/fee-management.svg";
import bannerIcon from "assets/icons/bannerAdver.svg";
import uDictionary from "assets/icons/user-management.svg";
import adminpic from "assets/icons/image.png";
import dropdownIcon from "assets/icons/Dropdown.svg";
import charityIcon from "assets/icons/charity.svg";
import mytsionOrder from "assets/icons/mytsionOrder.svg";

import { useGetLoggedInUser, useUpdateEmail } from "utils/api/user.api";
import Modal from "components/modal"; // Ensure you have a Modal component

import ChangePassword from "views/changePassword"; // Ensure you have this component
import logo from "assets/icons/logo.svg";
import { Input } from "components/input";
import { type SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useVerifyEmail } from "utils/api/auth.api";
import { limit } from "utils/helper";
import { type UpdateUserEmail } from "utils/types/user.type";

export default function Layout({
  children,
}: {
  children?: JSX.Element | JSX.Element[];
}) {
  const navigate = useNavigate();
  const { data: profile } = useGetLoggedInUser();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [selectMenue, setelectMenue] = useState("Admin Management");

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    // naviagte
    navigate("/logout");
  };
  const navItems = [
    {
      path: "/pending-withdrawals",
      label: "Transaction Approval",
      icon: pTransImg,
      width: "20px",
    },
    {
      path: "/transaction-history",
      label: "Transaction History",
      icon: cTransImg,
      width: "20px",
    },
    {
      path: "/fee-management",
      label: "Fee Management",
      icon: feeManagement,

      fontSize: "11.5px",
      lineHeight: "9px",
      width: "20px",
    },

    {
      path: "/users",
      label: "User Management",
      icon: uDictionary,
      width: "20px",
    },

    {
      path: "/banner-advertisement",
      label: "Banner & Advertisement",
      icon: bannerIcon,

      fontSize: "11.5px",
      lineHeight: "9px",
      width: "20px",
    },
    {
      path: "/charity-donation",
      label: "Charity & Donation",
      icon: charityIcon,

      fontSize: "11.5px",
      lineHeight: "9px",
      width: "20px",
    },
    {
      path: "/mystion-order",
      label: "Mystion Order",
      icon: mytsionOrder,
      width: "20px",
    },
  ];

  const {
    mutate: updateEmail,
    isSuccess,
    isLoading,
    reset: resetEmail,
  } = useUpdateEmail();

  const { mutate: verifyEmail, isSuccess: verifySuccess } = useVerifyEmail();
  const {
    register,
    handleSubmit,
    formState: { errors },

    reset: resetForm,
  } = useForm<UpdateUserEmail>();

  const onSubmit: SubmitHandler<UpdateUserEmail> = (data) =>
    isSuccess
      ? verifyEmail({ email: data?.email, otp: data?.otp })
      : updateEmail(data);

  useEffect(() => {
    if (verifySuccess) {
      resetForm();
      resetEmail();
      toast.success("Email updated successfully!");
    }
  }, [verifySuccess]);

  return (
    <main className=" flex flex-col overscroll-none  min-w-[330px]">
      <header className="w-full bg-white h-20   flex items-center justify-between drop-shadow-md z-30 p-4 lg:p-0">
        <div className="w-[270px] flex justify-start lg:justify-center items-center  border-r-0 lg:border-r  border-black-800/20 h-20 ">
          <img
            style={{ height: "40px", cursor: "pointer" }}
            className=" hidden lg:flex "
            src={logo}
            alt=""
          />{" "}
          <img
            style={{ height: "50px", cursor: "pointer" }}
            className="flex lg:hidden "
            src={logo}
            alt=""
            onClick={toggleSidebar}
          />{" "}
        </div>
        <nav className="flex items-center gap-12 lg:gap-6 p-4">
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <img
              style={{ height: "45px", borderRadius: "50%" }}
              src={profile?.image ? profile?.image : adminpic}
              alt="Admin"
            />
            <div>
              <p className="text-xl font-bold text-black-800">
                {profile?.fullname ? profile?.fullname : "Admin"}
              </p>
              <p style={{ color: "#909090" }} className="text-sm font-normal">
                {profile?.email}
              </p>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#EEEEEE",
              borderRadius: "50%",
              padding: "10px",
              paddingTop: "14px",
              position: "relative",
            }}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <img src={dropdownIcon} alt="Dropdown" className="max-w-fit" />

            {dropdownOpen && (
              <div className="absolute right-0 top-10 mt-2 w-64 bg-white rounded-lg border border-[#F1F1F1] shadow-lg z-50">
                <ul className="py-1 px-4">
                  {profile?.isSuperAdmin && (
                    <li
                      className="border-b border-[#F1F1F1] "
                      onClick={() => setelectMenue("Admin Management")}
                    >
                      <NavLink
                        to="/admin-management"
                        className={`${
                          selectMenue === "Admin Management"
                            ? "text-[#030319] font-[600]"
                            : "text-[#8F92A1]"
                        } block  py-2 text-sm  hover:text-[#030319] transition hover:font-semibold`}
                      >
                        Admin Management
                      </NavLink>
                    </li>
                  )}
                  {profile?.isAdmin && (
                    <>
                      <li
                        className="border-b border-[#F1F1F1] "
                        onClick={() => setelectMenue("Change Email")}
                      >
                        <span
                          onClick={() => setEmailModalOpen(true)}
                          className={`${
                            selectMenue === "Change Email"
                              ? "text-[#030319] font-[600]"
                              : "text-[#8F92A1]"
                          } block  py-2 text-sm   hover:text-[#030319] transition hover:font-semibold`}
                        >
                          Change Email
                        </span>
                      </li>
                      <li
                        className="border-b border-[#F1F1F1] "
                        onClick={() => setelectMenue("Change Password")}
                      >
                        <span
                          onClick={() => setPasswordModalOpen(true)}
                          className={`${
                            selectMenue === "Change Password"
                              ? "text-[#030319] font-[600]"
                              : "text-[#8F92A1]"
                          } block  py-2 text-sm   hover:text-[#030319] transition hover:font-semibold`}
                        >
                          Change Password
                        </span>
                      </li>
                      <li onClick={() => setelectMenue("Logout")}>
                        <span
                          onClick={() => setLogoutModalOpen(true)}
                          className={`${
                            selectMenue === "Logout"
                              ? "text-[#030319] font-[600]"
                              : "text-[#8F92A1]"
                          } block  py-2 text-sm   hover:text-[#030319] transition hover:font-semibold`}
                        >
                          Logout
                        </span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            )}
          </div>
        </nav>
      </header>
      <div className=" flex  flex-grow overflow-hidden ">
        <aside
          className={`sidebar bg-white border-r border-t border-black-800/20 flex flex-col   transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0 transition-transform duration-100 ease-in-out lg:static absolute z-30 top-15 h-full lg:h-auto w-50 lg:w-64`}
          style={{ minWidth: "270px" }}
        >
          <div className=" ">
            <nav>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "16px",
                }}
              >
                {navItems.map(
                  ({ path, label, icon, width, fontSize, lineHeight }) => (
                    <li key={path}>
                      <NavLink
                        to={path}
                        className="flex items-center text-[#030319] text-[14px] font-Poppins transition hover:font-bold"
                        style={({ isActive }) =>
                          isActive
                            ? {
                                boxShadow:
                                  "0px 0px 2px 4px rgba(247, 166, 0, 0.1)",

                                border: "1px solid #EDF9FF",
                                background: "var(--Color-Base-White, #FFFFFF)",
                                padding: "16px",
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                borderRadius: "10px",
                                ...(fontSize && { fontSize }),
                                ...(lineHeight && { lineHeight }),
                              }
                            : { padding: "16px", whiteSpace: "nowrap" }
                        }
                      >
                        <img
                          className="mr-2"
                          width={width}
                          src={icon}
                          alt={label}
                        />

                        {label}
                      </NavLink>
                    </li>
                  )
                )}
              </ul>
            </nav>
            {/* <nav>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",

                  padding: "16px",
                }}
              >
                <li>
                  <NavLink
                    to="/pending-withdrawals"
                    className="flex items-center text-[#030319] text-[12px] transition hover:font-bold"
                    style={({ isActive }) =>
                      isActive
                        ? {
                            boxShadow: "0px 4px 16px 0px #4DAAEC",
                            border: "1px solid #FFF5F2",
                            background: "var(--Color-Base-White, #FFFFFF)",
                            padding: "16px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            borderRadius: "10px",
                          }
                        : { padding: "16px", whiteSpace: "nowrap" }
                    }
                  >
                    <img
                      className="mr-2"
                      src={pTransImg}
                      alt="Transaction Approval"
                    />
                    Transaction Approval
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/transaction-history"
                    className="flex items-center text-[#030319] text-[12px] transition hover:font-bold"
                    style={({ isActive }) =>
                      isActive
                        ? {
                            boxShadow: "0px 4px 24px 0px #FE866429",
                            border: "1px solid #FFF5F2",
                            background: "var(--Color-Base-White, #FFFFFF)",
                            padding: "16px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            borderRadius: "10px",
                          }
                        : { padding: "16px", whiteSpace: "nowrap" }
                    }
                  >
                    <img
                      className="mr-2"
                      src={cTransImg}
                      alt="Transaction History"
                    />
                    Transaction History
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/earning-fee"
                    className="flex items-center text-[#030319] text-[12px] transition hover:font-bold"
                    style={({ isActive }) =>
                      isActive
                        ? {
                            boxShadow: "0px 4px 24px 0px #FE866429",
                            border: "1px solid #FFF5F2",
                            background: "var(--Color-Base-White, #FFFFFF)",
                            padding: "16px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            borderRadius: "10px",
                            lineHeight: "9px",
                            fontSize: "11.5px",
                          }
                        : { padding: "16px", whiteSpace: "nowrap" }
                    }
                  >
                    <img
                      className="mr-2"
                      src={CryptoFeeMangIcon}
                      alt="Fee Management"
                    />
                    Crypto Fee Management
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/fiat-fee-management"
                    className="flex items-center text-[#030319] text-[12px] transition hover:font-bold"
                    style={({ isActive }) =>
                      isActive
                        ? {
                            boxShadow: "0px 4px 24px 0px #FE866429",
                            border: "1px solid #FFF5F2",
                            background: "var(--Color-Base-White, #FFFFFF)",
                            padding: "16px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            borderRadius: "10px",
                          }
                        : { padding: "16px", whiteSpace: "nowrap" }
                    }
                  >
                    <img
                      className="mr-2"
                      src={feeManagement}
                      alt="Fee Management"
                    />
                    Fiat Fee Management
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/rates"
                    className="flex items-center text-[#030319] text-[12px] transition hover:font-bold"
                    style={({ isActive }) =>
                      isActive
                        ? {
                            boxShadow: "0px 4px 24px 0px #FE866429",
                            border: "1px solid #FFF5F2",
                            background: "var(--Color-Base-White, #FFFFFF)",
                            padding: "16px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            borderRadius: "10px",
                          }
                        : { padding: "16px", whiteSpace: "nowrap" }
                    }
                  >
                    <img
                      className="mr-2"
                      src={RatesIcon}
                      alt="Fee Management"
                    />
                    Rate
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/users"
                    className="flex items-center text-[#030319] text-[12px] transition hover:font-bold"
                    style={({ isActive }) =>
                      isActive
                        ? {
                            boxShadow: "0px 4px 24px 0px #FE866429",
                            border: "1px solid #FFF5F2",
                            background: "var(--Color-Base-White, #FFFFFF)",
                            padding: "16px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            borderRadius: "10px",
                          }
                        : { padding: "16px", whiteSpace: "nowrap" }
                    }
                  >
                    <img
                      className="mr-2"
                      src={uDictionary}
                      alt="User Management"
                    />
                    User Management
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/kyc"
                    className="flex items-center text-[#030319] text-[12px] transition hover:font-bold"
                    style={({ isActive }) =>
                      isActive
                        ? {
                            boxShadow: "0px 4px 24px 0px #FE866429",
                            border: "1px solid #FFF5F2",
                            background: "var(--Color-Base-White, #FFFFFF)",
                            padding: "16px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            borderRadius: "10px",
                          }
                        : { padding: "16px", whiteSpace: "nowrap" }
                    }
                  >
                    <img className="mr-2" src={card} alt="kyc" />
                    KYC
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/banner-advertisement"
                    className="flex items-center text-[#030319] text-[12px] transition hover:font-bold"
                    style={({ isActive }) =>
                      isActive
                        ? {
                            boxShadow: "0px 4px 24px 0px #FE866429",
                            border: "1px solid #FFF5F2",
                            background: "var(--Color-Base-White, #FFFFFF)",
                            padding: "16px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            borderRadius: "10px",
                            lineHeight: "9px",
                            fontSize: "11.5px",
                          }
                        : { padding: "16px", whiteSpace: "nowrap" }
                    }
                  >
                    <img className="mr-2" src={bannerIcon} alt="banner" />
                    Banner & Advertisement
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/account-management"
                    className="flex items-center text-[#030319] text-[12px] transition hover:font-bold"
                    style={({ isActive }) =>
                      isActive
                        ? {
                            boxShadow: "0px 4px 24px 0px #FE866429",
                            border: "1px solid #FFF5F2",
                            background: "var(--Color-Base-White, #FFFFFF)",
                            padding: "16px",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            borderRadius: "10px",
                          }
                        : { padding: "16px", whiteSpace: "nowrap" }
                    }
                  >
                    <img
                      className="mr-2"
                      src={AccountIcon}
                      alt="account Management"
                    />
                    Account Management
                  </NavLink>
                </li>
              </ul>
            </nav> */}
          </div>
        </aside>

        <section
          style={{ backgroundColor: "#FAFAFA" }}
          className={`p-4 lg:p-8 h-screen flex-grow overflow-auto  transition-all duration-300"
          `}
        >
          {isOpen && (
            <div
              className="fixed inset-0 bg-black opacity-50 lg:hidden"
              onClick={toggleSidebar}
            ></div>
          )}
          {children}
        </section>
      </div>
      {/* Email Change Modal */}
      <Modal isOpen={emailModalOpen} onClose={() => setEmailModalOpen(false)}>
        <div className="w-full">
          <div className="w-full">
            <div className="border-b border-[#F1F1F1] w-full pb-3">
              <p className="text-black-900 font-bold text-[18px]">
                Change your email address
              </p>
            </div>

            <form
              className="max-w-lg mx-auto pt-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              {isSuccess ? (
                <div className="mb-4">
                  <Input
                    isInvalid={Boolean(errors?.otp)}
                    errorMessage={errors?.otp?.message}
                    label="Verification code"
                    type="number"
                    register={register}
                    name="otp"
                    placeholder=""
                    rules={{ required: "Code is required" }}
                    inputProps={{
                      onKeyDown: (e) => limit(e),
                      onKeyUp: (e) => limit(e),
                      className:
                        "w-full bg-black-300 border border-black-300 px-4 py-4 rounded focus:outline-0",
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="mb-4">
                    <Input
                      type="email"
                      label="Old Email"
                      register={register}
                      errorMessage={errors?.oldEmail?.message}
                      isInvalid={Boolean(errors?.oldEmail)}
                      name="oldEmail"
                      placeholder=" "
                      rules={{
                        required: "Old email is required",
                      }}
                      inputProps={{
                        className:
                          "w-full bg-black-300 border border-black-300 px-4 py-4 rounded focus:outline-0",
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      type="email"
                      label="New Email"
                      register={register}
                      errorMessage={errors?.email?.message}
                      isInvalid={Boolean(errors?.email)}
                      name="email"
                      placeholder=" "
                      rules={{
                        required: "New email is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Enter valid email.",
                        },
                      }}
                      inputProps={{
                        className:
                          "w-full bg-black-300 border border-black-300 px-4 py-4 rounded-lg focus:outline-0",
                      }}
                    />
                  </div>
                  <div className="mb-8">
                    <Input
                      type="password"
                      label="Password"
                      register={register}
                      placeholder="******** "
                      errorMessage={errors?.password?.message}
                      isInvalid={Boolean(errors?.password)}
                      name="password"
                      rules={{
                        required: "Password is required",
                      }}
                      inputProps={{
                        className:
                          "w-full bg-black-300 border border-black-300 px-4 py-4 rounded-lg focus:outline-0",
                      }}
                    />
                  </div>
                </>
              )}
              <div className="w-full flex items-center justify-center gap-5">
                <button
                  type="button"
                  onClick={() => {
                    resetForm();
                    resetEmail();
                    setEmailModalOpen(false);
                  }}
                  className="w-full text-[#000000]  bg-[#F8F8F8] font-[600] text-[16px] rounded-lg px-8 py-4"
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-[16px] font-medium border border-cyan-800 rounded-lg px-8 py-4 transition w-full"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="w-5 h-5 rounded-full animate-spin mx-auto border-2 border-solid border-cyan-800 border-t-transparent"></div>
                  ) : (
                    "SAVE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {/* Password Change Modal */}
      <Modal
        isOpen={passwordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
      >
        <ChangePassword setPasswordModalOpen={setPasswordModalOpen} />
      </Modal>

      {logoutModalOpen && (
        <div className="fixed inset-0 flex items-center bg-black-800 bg-opacity-50 overflow-auto  justify-center z-50">
          <div className="relative flex items-center justify-center bg-white rounded overflow-auto-lg p-6 max-w-lg w-full mx-4">
            <div className="flex flex-col items-center gap-8  w-full h-full">
              <h1 className="text-[20px] font-[600] text-[#030319] text-[left] w-full border-b border-[#F1F1F1] pb-4">
                Logout
              </h1>
              <p className="text-left w-full text-[#8F92A1] text-[16px] font-[400] ">
                {" "}
                Are you sure you want to end this session.
              </p>
              <div style={{ display: "flex", gap: "32px", width: "100%" }}>
                {" "}
                <button
                  onClick={() => setLogoutModalOpen(false)}
                  className="text-[#000000] text-[16px] font-[400] bg-[#F8F8F8]  rounded-lg px-8 py-4 w-full"
                >
                  CANCEL
                </button>
                <button
                  onClick={handleLogout}
                  className="bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-[16px] font-[400] border border-cyan-800 rounded-lg px-8 py-4 transition w-full whitespace-nowrap"
                >
                  YES, LOGOUT
                </button>
              </div>{" "}
            </div>{" "}
          </div>
        </div>
      )}
    </main>
  );
}
